<template>
    <div>
      <el-form label-position="right" v-if="!field.is_global_variable">
        <title-and-description :field="field" />
        <el-row type="flex" :gutter="30">
          <el-col :span="12">
            <placeholder :field="field" />
          </el-col>
          <el-col :span="12">
            <field-filled-by :field="field" />
          </el-col>
        </el-row>
        <el-row type="flex" :gutter="30">
          <el-col :span="12">
          <is-field-required :field="field" class="field-required"/>
          </el-col>
          <el-col :span="10">
        <div class="icon-dropdown-selection">
            <el-button type="text" @click="iconPickerdialogVisible = true">
              <div class="d-flex align-center menu-icon">
                <span>
                  <icons :iconName="templateIconPath"></icons>
                </span>
                <i class="el-icon-circle-plus"></i>
              </div>
            </el-button>
          </div>
          </el-col>
        </el-row>
      </el-form>
  
      <el-form label-position="right" v-if="field.is_global_variable">
        <el-row type="flex" :gutter="30">
          <el-col :span="12">
            <div class="form-group">
            
              <el-form-item label="Global Variable">
                <el-select v-model="field.global_variable_id" @change="setGlobalVariable">
                  <el-option
                    v-for="(globalVariable,index) of allGlobalVariables"
                    :key="index"
                    :label="globalVariable.label"
                    filterable
                    :value="globalVariable._id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
          </el-col>
        </el-row>
        <el-row type="flex" :gutter="30">
          <el-col :span="12">
            <placeholder :field="field" />
          </el-col>
          <el-col :span="12">
            <field-filled-by :field="field" />
          </el-col>
        </el-row>
        <is-field-required :field="field" class="field-required"/> 
      </el-form>
      <dialog-component
      :title="'Select An Icon'"
      :visible="iconPickerdialogVisible"
      :containerWidth="getIsMobile ? '100%' : '77%'"
      @before-close="closeSelectIcon"
      :isShowFooter="false"
      :selectedIcon="field.icon_name"
    >
      <div class="icons-component">
        <div class="iconslistScrollable">
          <icons-list @icon-data="getIconPath"></icons-list>
        </div>
      </div>
    </dialog-component>
    </div>
  </template>
  
  <script>
  // import TitleAndDescription from "./TitleAndDescription";
  // import IsFieldRequired from "./IsFieldRequired";
  // import FieldFilledBy from "./FieldFilledBy";
  // import FieldAttributes from "./FieldAttributes";
  // import Placeholder from "./Placeholder";
  //import { mapGetters } from "vuex";
  import { fetchGlobalVariables } from "@/repo/globalVariables"
  export default {
    name:"templates-formComponents-Audio",
    components: {
       "TitleAndDescription":()=>import("./TitleAndDescription"),
      "IsFieldRequired":()=>import("./IsFieldRequired"),
      "FieldFilledBy":()=>import("./FieldFilledBy"),
      "Placeholder":()=>import("./Placeholder"),
    },
    
    props: ["field"],
    computed: {
    //  ...mapGetters("globalVariables", ["getAllGlobalVariables"]),
      allGlobalVariables() {
        return this.getAllGlobalVariables
          ? this.getAllGlobalVariables.data || []
          : [];
      }
    },
    mounted() {
      this.fetchGlobalVaribales();
    },
    data() {
      return {
        iconPickerdialogVisible: false,
        getAllGlobalVariables: null
      };
    },
    methods: {
    closeSelectIcon() {
      this.iconPickerdialogVisible = false;
    },
    getIconPath(icon) {
      this.templateIconPath = icon;
      this.$set(this.field, 'icon_name', icon);
      this.iconPickerdialogVisible = false;
    },
      async fetchGlobalVaribales() {
       
        let params = {
          get_all: true,
          input_type: this.field.input_type
        };
        this.getAllGlobalVariables = await fetchGlobalVariables(params);
      },
      setGlobalVariable() {
        let globalVariable = this.allGlobalVariables.find(
          x => x._id == this.field.global_variable_id
        );
        this.field.label = globalVariable.label;
        this.field.description = globalVariable.description;
        this.field.options = globalVariable.options;
      }
    }
  };
  </script>
  
  <style lang="scss">
  </style>